/*
* Login Page
*
* This component display the login page
*/


import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from 'src/services/auth/auth.service';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup;
  loginFormStatus!: boolean;
  showPassword: boolean = false;
  unauthorizedWarning: boolean = false;

  constructor(private router: Router, private authService: AuthService) {
  }

  ngOnInit() {
    environment.login.resource_id != "RC_EMEA_INT" ? this.authService.startAuthProcess() : () => {
    };
    this.loginForm = new FormGroup({
      username: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required),
    });
  }

  onSubmit() {
    switch (this.loginForm.get('username')?.value.toUpperCase()) {
      case "GAP":
        this.authService.startAuthProcess();
        break;
      case "APPROVER":
        this.authService.setDeveloperMode("rc-approver", [`rc-approveevents`, `rc-comment`, `rc-tasks`, `rc-viewevents`, `rc-webnotifications`]);
        break;
      case "CARTOGRAPHER":
        this.authService.setDeveloperMode("rc-cartographer", [`rc-closeevents`, `rc-comment`, `rc-createevents`, `rc-modifyevents`, `rc-tasks`, `rc-viewevents`, `rc-webnotifications`]);
        break;
      case "ADMIN":
        this.authService.setDeveloperMode("rc-admin", [`rc-approveevents`, `rc-closeevents`, `rc-comment`, `rc-createevents`, `rc-modifyevents`, `rc-tasks`, `rc-togglerole`, `rc-viewevents`, `rc-webnotifications`]);
        break;
      default:
        this.unauthorizedWarning = true;
        setTimeout(() => {
          this.unauthorizedWarning = false;
        }, 3000);
        break;
    }
  }

  togglePwd() {
    this.showPassword = !this.showPassword;
  }
}
