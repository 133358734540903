import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpContextToken
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, shareReplay } from 'rxjs/operators';

export const BYPASS_INTERCEPTOR = new HttpContextToken(() => false);

@Injectable({
  providedIn: 'root'
})
export class CachingInterceptor implements HttpInterceptor {
  private cache = new Map<string, any>();
  private inFlightRequests = new Map<string, Observable<any>>();

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.method !== 'GET' || req.context.get(BYPASS_INTERCEPTOR)) {
      return next.handle(req);
    }

    const cacheKey = req.urlWithParams;
    if (this.cache.has(cacheKey)) {
      const cachedResponse = this.cache.get(cacheKey);
      return of(new HttpResponse({
        body: cachedResponse,
        status: 200,
        statusText: 'OK'
      }));
    }

    if (this.inFlightRequests.has(cacheKey)) {
      return this.inFlightRequests.get(cacheKey)!;
    }

    const request$ = next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          this.cache.set(cacheKey, event.body);
        }
      }),
      shareReplay(1)
    );

    this.inFlightRequests.set(cacheKey, request$);
    request$.subscribe(() => {
      this.inFlightRequests.delete(cacheKey);
    });

    return request$;
  }
}
