/*
 * Event Utility Service
 *
 * This service contains all the utilities for the management of the events
 */

import { Injectable } from '@angular/core';
import * as L from 'leaflet';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CardEventData } from 'src/model/Event';

@Injectable({
  providedIn: 'root',
})
export class EventUtilityService {
  private selectedToolSub$ = new BehaviorSubject<string>('SEGMENT');
  private valueCardSelected = new BehaviorSubject<any>(null);
  private rightColumnNoDetails = new BehaviorSubject<any>(false);
  private typeInteractionEvent = new BehaviorSubject<any>(null);
  private oddSelectedEvent = new BehaviorSubject<any>(null);
  private allEvents = new BehaviorSubject<any>(null);
  private allUnfilteredEvents = new BehaviorSubject<any>(null);
  private getAllEvents = new BehaviorSubject<any>(false);
  private getAllUnfilteredEvents = new BehaviorSubject<any>(false);
  private filterChangeEvent = new BehaviorSubject<any>(null);
  private refreshCurrentEvent = new BehaviorSubject<any>(null);
  private getEventsOnSearchAddress = new BehaviorSubject<boolean>(false);
  private closeFiltersFromButton = new BehaviorSubject<any>(false);
  private clearUnusedDrawings = new BehaviorSubject<any>(false);
  private eventCoordinates = new Subject<L.LatLng>;
  private eventToRemove = new Subject<string>;
  private stateSelection = new Subject<{stateName: string, stateCoordinate: {latitude: number, longitude: number}} | undefined>;
  private hasMultipleRoles = new BehaviorSubject<boolean>(false);
  private zoomLevel = new BehaviorSubject<number>(4);

  closeFiltersFromButtonObservable = this.closeFiltersFromButton.asObservable();
  valueCardSelectedObservable = this.valueCardSelected.asObservable();
  rightColumnNoDetailsObservarble = this.rightColumnNoDetails.asObservable();
  typeInteractionEventObservable = this.typeInteractionEvent.asObservable();
  oddSelectedObservable = this.oddSelectedEvent.asObservable();
  filterChangeObservable = this.filterChangeEvent.asObservable();
  allEventsObservable = this.allEvents.asObservable();
  allUnfilteredEventsObservable = this.allUnfilteredEvents.asObservable();
  getAllEventsObservable = this.getAllEvents.asObservable();
  getAllUnfilteredEventsObservable = this.getAllUnfilteredEvents.asObservable()
  refreshCurrentObservable = this.refreshCurrentEvent.asObservable();
  getEventsOnSearchAddressObservable = this.getEventsOnSearchAddress.asObservable();
  clearUnusedDrawingsObservable = this.clearUnusedDrawings.asObservable();
  eventCoordinatesObservable = this.eventCoordinates.asObservable();
  eventToRemoveObservable = this.eventToRemove.asObservable();
  stateSelectionObservable = this.stateSelection.asObservable();
  hasMultipleRolesObservable = this.hasMultipleRoles.asObservable();
  zoomLevelObservable = this.zoomLevel.asObservable();
  selectedTool$ = this.selectedToolSub$.asObservable();
  private multipleEventsSelectedSubject = new BehaviorSubject<any[]>([]);
  private multieventCreationSub$ = new BehaviorSubject<{eventType:string,multiEventModal: boolean, editMultiEventModal:boolean}>({eventType:'createEvents',multiEventModal: false,editMultiEventModal:false});
  multipleEventsSelectedObservable = this.multipleEventsSelectedSubject.asObservable();
  multieventCreationObservable = this.multieventCreationSub$.asObservable();
  private newEventSubject = new BehaviorSubject<any>(null);
  newEventObservable = this.newEventSubject.asObservable();

  selectedOddSegmentsOngoingChange: any[] =[];
  visualisedPolylines: any[] = [];
  isSegmentEditOngoing: boolean = false;

  existingOddSelection: {
    id: string,
    coordinates: {lat: number, lng: number}[],
    tileId: string,
    linkId: string,
    stableTopologyId: string,
  }[] = [
    {
    id: '623609197-1904-BASE_LINK_WITH_GEOMETRY',
    coordinates: [
      {lat: 42.33200937509537, lng: -83.06155040860176},
      {lat: 42.3318799585104, lng: -83.06143037974834},
      {lat: 42.33162984251976, lng: -83.06119032204151},
      {lat: 42.33149975538254, lng: -83.06105017662048},
      {lat: 42.33139984309673, lng: -83.06096032261848},
      {lat: 42.331259697675705, lng: -83.06083023548126},
      {lat: 42.33095996081829, lng: -83.06049026548862},
    ],
    tileId: "623609197",
    linkId: "623609197-1904-BASE_LINK_WITH_GEOMETRY",
    stableTopologyId: "480195769",
  }
]

// [
//   {
//   coordinates: [
//   {latitude: 42.33200937509537, longitude: -83.06155040860176},

// ]},
// {
// coordinates:  [ 
//   {latitude: 42.33149975538254, longitude: -83.06105017662048},
//   {latitude: 42.33139984309673, longitude: -83.06096032261848},
// ]}
// ]

// [
//   {
//   coordinates: [
//   {lat: 42.33200937509537, lng: -83.06155040860176},

// ]},
// {
// coordinates:  [ 
//   {lat: 42.33149975538254, lng: -83.06105017662048},
//   {lat: 42.33139984309673, lng: -83.06096032261848},
// ]}
// ]

  emitNewEvent(event: any): void {
    this.newEventSubject.next(event);
  }

  setMultipleEventsSelected(events: any[]): void {
    this.multipleEventsSelectedSubject.next(events);
  }

  getMultipleEventsSelected(): any[] {
    return this.multipleEventsSelectedSubject.getValue();
  }

  setMultipleEventsCreation(action: {eventType:string,multiEventModal: boolean,editMultiEventModal:boolean}): void {
    this.multieventCreationSub$.next(action);
  }

  geMultipleEventsCreation(): {eventType:string,multiEventModal: boolean,editMultiEventModal:boolean} {
    return this.multieventCreationSub$.getValue();
  }

  groupEventsBySharedSegments(events: any[]): Map<string, any[]> {
    // Step 1: Create mappings of segments -> events
    const segmentToEvents = new Map<string, Set<string>>();
    events.forEach((event) => {
        event.linkStableIds.forEach((stableIdObj: any) => {
            const stableId = stableIdObj.stable_topology_id;
            if (!segmentToEvents.has(stableId)) {
                segmentToEvents.set(stableId, new Set());
            }
            segmentToEvents.get(stableId)?.add(event.eventId);
        });
    });

    // Step 2: Create una mappings event -> correlated events
    const eventGraph = new Map<string, Set<string>>();
    segmentToEvents.forEach((eventIds) => {
        const eventArray = Array.from(eventIds);
        for (let i = 0; i < eventArray.length; i++) {
            for (let j = i + 1; j < eventArray.length; j++) {
                const event1 = eventArray[i];
                const event2 = eventArray[j];
                if (!eventGraph.has(event1)) eventGraph.set(event1, new Set());
                if (!eventGraph.has(event2)) eventGraph.set(event2, new Set());
                eventGraph.get(event1)?.add(event2);
                eventGraph.get(event2)?.add(event1);
            }
        }
    });

    // Step 3: Find correlated events
    const visited = new Set<string>();
    const groups: any[] = [];

    const dfs = (eventId: string, group: Set<string>) => {
        visited.add(eventId);
        group.add(eventId);
        eventGraph.get(eventId)?.forEach((neighbor) => {
            if (!visited.has(neighbor)) dfs(neighbor, group);
        });
    };

    eventGraph.forEach((_, eventId) => {
        if (!visited.has(eventId)) {
            const group = new Set<string>();
            dfs(eventId, group);
            groups.push(group);
        }
    });

    // Step 4: Create final mapping group -> events
    const groupedMap = new Map<string, any[]>();
    groups.forEach((group, index) => {
        const groupId = `Gruppo_${index + 1}`;
        const groupedEvents = Array.from(group).map((eventId) =>
            events.find((event) => event.eventId === eventId)
        );
        groupedMap.set(groupId, groupedEvents);
    });

    return groupedMap;
  }

  setValueCardSelected(value: any): void {
    this.valueCardSelected.next(value);
  }
  selectedTool(tool: string) {
    this.selectedToolSub$.next(tool);
  }

  setZoomLevel(zoomLevel: number) {
    this.zoomLevel.next(zoomLevel)
  }
  getZoomLevel() {
    return this.zoomLevel.value
  }

  cancelStateNameSelection() {
    this.stateSelection.next(undefined)
  }
  changeStateSelection(newValue: {stateName: string, stateCoordinate: {latitude: number, longitude: number}} | undefined) {
    this.stateSelection.next(newValue)
  }
  setHasMultipleRoles(value: boolean) {
    this.hasMultipleRoles.next(value)
  }

  changeEventCoordinates(newValue: L.LatLng) {
    this.eventCoordinates.next(newValue)
  }

  toggleGetAllEvents() {
    this.getAllEvents.next(!this.getAllEvents.value)
  }

  triggerGetAllUnfilteredEvents() {
    this.getAllUnfilteredEvents.next(!this.getAllUnfilteredEvents.value)
  }

  triggerGetEventsOnSearchAddress() {
    this.getEventsOnSearchAddress.next(!this.getEventsOnSearchAddress.value)
    // this.cancelOddSelectedValue();
    // this.selectedOddSegmentsOngoingChange = []
  }

  triggerCloseFiltersFromButton() {
    this.closeFiltersFromButton.next(!this.closeFiltersFromButton.value)
  }

  triggerClearUnusedDrawings() {
    this.clearUnusedDrawings.next(!this.clearUnusedDrawings.value)
  }

  triggerEventRemoval(eventId: string) {
    this.eventToRemove.next(eventId)
  }

  changeTypeInteractionEvent(newValue: string) {
    this.typeInteractionEvent.next(newValue);
  }
  getTypeInteractionEvent() {
    return this.typeInteractionEvent.value
  }

  changeRightColumnNoDetails() {
    this.rightColumnNoDetails.next(true);
  }

  changeValueCardSelected(newValue: any) {
    this.valueCardSelected.next(newValue);
  }
  cancelValueCardSelected() {
    this.valueCardSelected.next(null);
    this.changeTypeInteractionEvent("");
    this.cancelOddSelectedValue();
  }
  getValueCardSelected() {
    return this.valueCardSelected.value
  }

  changeOddSelectedValue(newValue: any) {
    this.oddSelectedEvent.next(newValue);
  }
  cancelOddSelectedValue() {
    this.oddSelectedEvent.next(null);
  }

  refreshCurrent(bypassInterceptor?: boolean){
    this.refreshCurrentEvent.next({bypassInterceptor})
  }

  changeFilter(newValue: any) {
    const currentValue = this.filterChangeEvent.value;
    const mergedValue = {...currentValue, ...newValue}
    this.filterChangeEvent.next(mergedValue);
  }
  cancelFilterValue() {
    this.filterChangeEvent.next(null);
  }

  changeAllEvents(newEvents:any[]){
    this.allEvents.next(newEvents)
  }
  cancelAllEvents(){
    this.allEvents.next(null);
  }
  changeAllUnfilteredEvents(newEvents:any[]){
    this.allUnfilteredEvents.next(newEvents)
  }
  cancelAllUnfilteredEvents(){
    this.allUnfilteredEvents.next(null);
  }

  getGeoDistanceApproximateKM(latA:number,lonA:number,latB:number,lonB:number){
    //distanza (A,B) = R * arccos(sin(latA) * sin(latB) + cos(latA) * cos(latB) * cos(lonA-lonB))
    const EarthRay = 6372.795477598;
    let d = EarthRay * Math.acos(Math.sin(latA) * Math.sin(latB) + Math.cos(latA) * Math.cos(latB) * Math.cos(lonA-lonB))
    return this.deg2rad(d);
  }

  deg2rad(degrees:number)
  {
    return degrees * (Math.PI/180);
  }

  rad2deg(rad:number)
  {
    return rad * (180/Math.PI);
  }

  extractSelectedOddSegmentsOngoingChangeFromCurrentEvent(event: any) {
    let oddSegmentData: {
      id: string,
      coordinates: {lat: number, lng: number}[],
      tileId: string,
      linkId: string,
      stableTopologyId: string,
    }[] = []

    function formatCoordinates(coordinatesData: {link_id: string, coordinates: {latitude: number, longitude: number}[]}[], linkId: string): {lat: number, lng: number}[] {
      const matchingCoordinates = coordinatesData.find(coord => coord.link_id == linkId);
      if (matchingCoordinates) {
        return matchingCoordinates.coordinates.map(coord => ({
          lat: coord.latitude,
          lng: coord.longitude,
        }));
      } else {
        return []; // Return an empty array if no matching coordinates are found
      }
    }

    event.linkStableIds.forEach((linkStableId: any) => {
      let oddSegment: {
        id: string,
        coordinates: {lat: number, lng: number}[],
        tileId: string,
        linkId: string,
        stableTopologyId: string,
      } = {
        id: linkStableId.link_id,
        coordinates: formatCoordinates(event.representativeGeo, linkStableId.link_id),
        tileId: linkStableId.link_id.split("-")[0],
        linkId: linkStableId.link_id,
        stableTopologyId: linkStableId.stable_topology_id,
      }
      oddSegmentData.push(oddSegment)
    })
    return oddSegmentData
  }

  extractMiddlePoint(event: any): {latitude: number, longitude: number} {
    let sumLatitudes: number = 0;
    let sumLongitudes: number = 0;
    let count = 0;

    event.representativeGeo.forEach((coordinateGroup:any) => {
      coordinateGroup.coordinates.forEach((coordinate: {latitude: number, longitude:number}) => {
        sumLatitudes = sumLatitudes + coordinate.latitude;
        sumLongitudes = sumLongitudes + coordinate.longitude;
        count = count + 1;
      });
    })

    const averageLat = sumLatitudes / count;
    const averageLng = sumLongitudes / count;
    const averageCoordinates = {
      latitude: averageLat,
      longitude: averageLng,
    }
    return averageCoordinates
  }

  extractStateMiddlePoint(coordinates: any, multiPoligon?: boolean): {latitude: number, longitude: number} {
    const isMultiPolygon: boolean = multiPoligon ? true : false

    let sumLatitudes: number = 0;
    let sumLongitudes: number = 0;
    let count = 0;

    if(isMultiPolygon) {
      coordinates.forEach((coordinateArray: any) => {
        coordinateArray[0].forEach((coordinate: [number, number]) => {
          sumLatitudes = sumLatitudes + coordinate[1];
          sumLongitudes = sumLongitudes + coordinate[0];
          count = count + 1;
        });
      });
    } else {
      coordinates.forEach((coordinate: [number, number]) => {
        sumLatitudes = sumLatitudes + coordinate[1];
        sumLongitudes = sumLongitudes + coordinate[0];
        count = count + 1;
      });
    }



    const averageLat = sumLatitudes / count;
    const averageLng = sumLongitudes / count;

    const averageCoordinates = {
      latitude: averageLat,
      longitude: averageLng,
    }

    return averageCoordinates
  }

  cleanUtilityService() {
    this.selectedOddSegmentsOngoingChange = [];
    this.cancelAllEvents();
    this.cancelFilterValue();
    this.cancelOddSelectedValue();
    this.cancelValueCardSelected();
    this.cancelStateNameSelection();
    this.setHasMultipleRoles(false);
    this.rightColumnNoDetails.next(false);
    this.typeInteractionEvent.next(null);
    this.refreshCurrentEvent.next(null);
    this.getAllEvents.next(null);
  }
}